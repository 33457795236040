.left-section {
  color: #7d7f84;
  margin-inline-end: 6px;
  font-size: 15px;
}
.confirm-mobile {
  height: 32px;
  display: flex;
  align-items: center;
}
.data-table {
  .ant-typography {
    margin-bottom: 26px;
  }
  margin-bottom: 50px;
  .ant-table-row {
    cursor: pointer;
  }
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }
}

.table-pagination {
  margin-top: 15px;
  &__elements {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__info {
    color: #86979f;
  }
  &__elect {
    width: 65px;
  }
}

.ant-table-column-title {
  flex: none;
  margin-inline-end: 5px;
}
.ant-table-column-sorters {
  justify-content: start;
}

.display-field {
  display: flex;
  align-items: center;
}

.field-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.users-mobile {
  div.ant-radio-group label {
    margin-inline-end: 8px;
    margin-bottom: 8px;
    border: 1px solid var(--roche-main);
    color: var(--roche-main);
  }
}
.user-list {
  .ant-form-item {
    margin-bottom: 0;
  }
  &.ant-list-split .ant-list-item {
    border-bottom: 1px solid #ebedf3;
    &:first-child {
      border-top: 1px solid #ebedf3;
    }
  }
}
.user-site_ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.edit-user,
.create-user {
  .ant-row {
    margin-block-end: 15px;
    h3 {
      display: flex;
      align-items: center;
      gap: 0.125em;
    }
    .ant-form-item-row {
      display: grid;
      grid-template-columns: 40% 57%;
      grid-column-gap: 3%;
    }
  }
}
.waiting-user {
  color: #a7a8ab;
  font-size: 14px;
  margin-left: 20px;
  font-weight: 500;

  span {
    margin-left: 6px;
  }
}

.text-bold {
  font-weight: bold;
}
.pin-code {
  &__text {
    font-weight: bold;
    font-size: 26px;
    margin: 0;
    display: flex;
    align-items: center;
  }

  &__time {
    font-weight: bold;
    color: var(--roche-main);
  }

  &__clone {
    color: var(--roche-main);
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
  }
}
.report-format {
  margin-top: 30px;
}
