.app-settings.ant-form-vertical {
  h3 {
    font-weight: bold;
  }
}
.theme-list {
  display: flex;
  justify-content: flex-start;
}

.theme-block {
  width: 25px;
  height: 25px;
  margin-inline-end: 15px;
}

.lock-time-editor-wrapper {
  .lock-time-editor-minutes-wrapper {
    max-height: 32px;
    margin-top: 8px;
    @media (min-width: 1200px) {
      margin-inline-start: 20px;
      margin-top: 0px;
    }
    .lock-time-editor-minutes-label{
      margin-inline-end: 5px;
    }
  }
}

.field-monitoring-statuses-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.list-title {
  display: block;
  width: 100%;
}
.add-new-question-form {
  margin-top: 22px;
}
