html,
body {
  --antd-wave-shadow-color: @main-roche-color;
  height: 100%;
  margin: 0;
}

body {
  font-family: Roche;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Problem with too large clikable checkbox area in vertical form layout */
.ant-form-item-label {
  label {
    width: fit-content;
  }
}
.ant-col-rtl.ant-form-item-label{
    text-align: right;
}

.ant-pagination-prev, .ant-pagination-next {
  margin-inline-start: 8px;
}
