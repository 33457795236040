.app-settings {
  max-width: 500px;
  .conf-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }
  .ant-switch,
  .ant-input-number {
    margin-inline-end: 10px;
  }
  .input-encrypted-wrapper {
    display: flex;
    > *:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-left: none;
    }
    > *:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .settings-bool-config {
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
  }
  .settings-number-config {
    &__label {
      white-space: nowrap;
    }
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
    .ant-input-number {
      min-width: 80px;
      height: 32px;
    }
  }
}
