@main-roche-color-theme1: #f0265c;
@text-color-inverse-theme1: #fff;
@red-base-theme1: #e40046;
@purple-base-theme1: #a05eb5;
@green-base-theme1: #00965e;
@yellow-base-theme1: #ffc72c;
@orange-base-theme1: #ed8b00;

.theme1 {
  header {
    background: @main-roche-color-theme1;
  }
  .ant-layout-sider-trigger {
    background: @main-roche-color-theme1;
  }
  .ant-menu {
    &-submenu {
      &-title {
        &:hover {
          color: @main-roche-color-theme1;

          & > .ant-menu-submenu-arrow {
            color: @main-roche-color-theme1;
          }
        }
      }

      & a {
        &:hover {
          color: @main-roche-color-theme1;
        }
      }
    }

    &-item {
      & a {
        &:hover {
          color: @main-roche-color-theme1;
        }
      }

      &-selected {
        color: @main-roche-color-theme1;
        & a {
          color: @main-roche-color-theme1;
        }
      }

      &:hover {
        color: @main-roche-color-theme1;
      }
      &:after {
        border-right-color: @main-roche-color-theme1;
      }
    }
  }
  .ant-btn {
    &:hover {
      border-color: @main-roche-color-theme1;
      color: @main-roche-color-theme1;
    }

    &-primary {
      border-color: @main-roche-color-theme1;
      background: @main-roche-color-theme1;
      color: @text-color-inverse-theme1;

      &:hover {
        color: @text-color-inverse-theme1;
        border-color: @text-color-inverse-theme1;
      }
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: rgba(0, 0, 0, 0.25);
      background: rgba(0, 0, 0, 0.25);
    }

    &-link {
      color: @main-roche-color-theme1;

      &:hover {
        color: @main-roche-color-theme1;
      }
      &:disabled {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
        border-color: transparent;
      }
    }
  }
  .ant-switch {
    &-checked {
      background: @main-roche-color-theme1;
    }
  }

  .ant-radio {
    &:hover {
      border-color: @main-roche-color-theme1;

      & .ant-radio-inner {
        border-color: @main-roche-color-theme1;
      }
    }

    &-checked {
      & .ant-radio-inner {
        border-color: @main-roche-color-theme1;

        &:after {
          background: @main-roche-color-theme1;
        }
      }
    }
  }

  .ant-checkbox {
    &-wrapper:hover {
      & .ant-checkbox-inner {
        border-color: @main-roche-color-theme1;
      }
    }

    &:hover {
      border-color: @main-roche-color-theme1;

      & .ant-checkbox-inner {
        border-color: @main-roche-color-theme1;
      }
    }

    &-checked {
      & .ant-checkbox-inner {
        border-color: @main-roche-color-theme1;
        background: @main-roche-color-theme1;

        &:after {
          background: @main-roche-color-theme1;
        }
      }

      &:after {
        border-color: @main-roche-color-theme1;
      }
    }

    &-disabled .ant-checkbox-inner {
      background: @disabled-bg;
      &:after {
        background: @disabled-bg;
      }
    }
  }

  table a {
    color: @main-roche-color-theme1;
  }

  .ant-input {
    &:hover {
      border-color: @main-roche-color-theme1;
    }

    &:focus {
      box-shadow: none;
    }
    &:focus {
      border-color: @main-roche-color-theme1;
    }
  }
  .ant-select {
    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: @main-roche-color-theme1;
    }

    &-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none;
      border-color: @main-roche-color-theme1;
    }

    &-item-option-selected {
      color: #fff;
      background-color: @main-roche-color-theme1;
    }
  }

  .action-drag {
    color: @main-roche-color-theme1;

    &:hover {
      color: @main-roche-color-theme1;
    }
  }

  .ant-tabs {
    &-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @main-roche-color-theme1;
    }

    &-ink-bar {
      background-color: @main-roche-color-theme1;
    }

    &-tab {
      &:hover {
        color: @main-roche-color-theme1;
      }
    }
  }
}
