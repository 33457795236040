.login {
  max-width: 450px;
  width: 100%;
  margin-inline: auto;

  .ant-card-head-title {
    white-space: pre-wrap;
  }
}
.mainImage {
  max-width: 450px;
}
.login-background {
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  height: 100%;
}

.login-form {
  height: calc(100vh - 48px);
  background: #fff;
  overflow: auto;
  padding: 30px 20px 0 20px;
}
.login-img {
  height: 35px;
  margin-bottom: 35px;
}

.login-title {
  margin-bottom: 0 !important;
  font-size: 26px !important;
}

.login-info {
  width: 100%;
  margin-bottom: 30px;
}

.login-caps-lock-info {
  position: absolute;
  inset-block-start: 15px;
  inset-inline-end: 15px;
  text-transform: uppercase;
  color: var(--roche-red);
}
.set-new-pswd {
  .gen-pswd {
    position: absolute;
    inset-inline-end: 0;
    cursor: pointer;
    z-index: 2;
    padding: 0 0 8px 0;
  }

  .expired-pswd {
    display: block;
    margin: 25px 0;
  }
  .form-button {
    margin-bottom: 20px;
  }
}
