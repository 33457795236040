.display-name {
  .ant-form-item {
    margin: 0;
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
    button {
      margin-inline-end: 5px;
      margin-top: 5px;
      &.field-button__required {
        font-weight: bold;
      }
    }
  }
}
