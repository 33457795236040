#root {
  overflow: auto;
  height: 100%;
}
.app-main.ant-layout {
  min-height: 100%;
}

.ant-layout-header {
  position: sticky;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  z-index: 4;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: @layout-header-color;
  }
}

button.ant-btn.burger-btn {
  color: @layout-header-color;
  background: transparent;
  border: none;
}

.ant-menu {
  box-shadow: none;
  &-item:hover,
  &-submenu-active .ant-menu-submenu-title {
    color: @main-roche-color;
    opacity: 0.7;
  }
  .ant-menu-item::after {
    border-color: @main-roche-color;
  }
}

.ant-menu-item-selected {
  color: @main-roche-color;
}

@open-menu: 200px;
@close-menu: 80px;
aside.main-menu.ant-layout-sider {
  position: fixed;
  top: @layout-header-height;
  height: calc(~'100vh - @{layout-header-height}');
  width: @open-menu !important;
  min-width: @open-menu !important;
  max-width: @open-menu !important;
  &.ant-layout-sider-collapsed {
    width: @close-menu !important;
    min-width: @close-menu !important;
    max-width: @close-menu !important;
    & + section.ant-layout.site-layout {
      margin-inline-start: @close-menu;
    }
  }
}

.main-menu {
  .ant-layout-sider-children {
    overflow: auto;
    height: calc(~'100vh - @{layout-header-height} - @{layout-trigger-height}');
  }
}

.main-menu + .site-layout {
  transition: @animation-duration-base;
  margin-inline-start: @open-menu;
}

button.ant-btn.burger-btn {
  margin: calc(~'@{layout-header-height} / 4') 0.5em;
}

.burger-btn svg.fa-bars {
  font-size: calc(~'@{layout-header-height} / 2');
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

header.ant-layout-header {
  display: flex;
  padding: 0;
  .logo a {
    display: block;
    background: @layout-header-color;
    width: @open-menu;
    height: @layout-header-height;
    padding: @layout-header-padding;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .main-menu-title {
    height: @layout-header-height;
    padding: 0 20px;
    white-space: nowrap;
    margin: 0;
  }
  .top-main-menu-element {
    height: @layout-header-height;
    padding: @layout-header-padding;
    margin-left: auto;
  }
}

.top-main-menu {
  display: flex;
  justify-content: flex-start;
  .ant-menu {
    background-color: transparent;
    color: @layout-header-color;
  }
}

.site-layout-content {
  padding: 20px;
}

.global-drag-line {
  border-color: @global-drag-line !important;
}

button.top-menu-element {
  color: @text-color-inverse;
}
.pswd-green {
  color: @green-base;
}
.pswd-red {
  color: @red-base;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: auto;
  height: auto;
  max-width: 48px;
  max-height: 48px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  height: auto;
  text-align: center;
}

.ant-dropdown {
  .top-menu {
    border: 1px solid rgb(240, 240, 240);
  }
  button.ant-btn.ant-btn-text:hover {
    background-color: transparent;
  }
}

.ant-table-thead > tr > th {
  background-color: #fff;
  font-size: 14px;
  color: var(--roche-gray-text);
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  width: 0;
}
tr td .ant-form-item {
  margin-bottom: 0;
}
.font-bold {
  font-weight: bold;
}
.display-end {
  display: flex;
  justify-content: flex-end;
}
div.ant-radio-group label::before {
  width: 0px !important;
}

.ant-dropdown-menu-item {
  a,
  button {
    position: static;
    display: block;
    padding: 5px 12px;
    width: 100%;
    height: 100%;
    text-align: left;
  }
}

.ant-dropdown-menu-title-content {
  & > button::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
  }
}

.mobile-menu {
  .ant-menu-item {
    padding: 0 24px;
    .ant-menu-title-content {
      button {
        padding: 0;
      }
    }
  }
}

.fa-info-circle {
  color: @gray-text;
}
