.confirm-email {
  padding-top: 40px;
  padding-bottom: 40px;
  height: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .card {
    max-width: 450px;
    margin: 0 auto;

    .title {
      text-align: center;
    }

    .disclaimer {
      padding-block: 15px;
      font-size: 16px;
      text-align: center;
    }
  }
}
