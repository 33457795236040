.field-form {
  form {
    display: flex;
    flex-direction: column;
  }

  &__header {
    font-weight: bold;
    font-size: 16px;
    margin-block: 12px 6px;
  }

  &__example .ant-form-item .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item {
    max-width: 500px;
  }

  &__label {
    order: 10;
  }
  &__type {
    order: 20;
  }
  &__properties {
    order: 30;
  }
  &__display-as {
    order: 40;
  }
  &__edit-as {
    order: 50;
  }
  &__required {
    order: 60;
  }
  &__registration {
    order: 70;
  }
  &__monitorable {
    order: 80;
  }
  &__monitoring-status {
    order: 90;
  }
  &__default {
    order: 100;
  }
  &__hint {
    order: 2010;
    margin-bottom: 0;
  }
  &__hide {
    order: 2020;
  }
  &__conditions {
    order: 2030;
  }
}
