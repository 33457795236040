.reset-password {
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .gen-pswd {
    position: absolute;
    inset-inline-end: 25px;
    cursor: pointer;
    z-index: 2;
    padding: 0 0 8px 0;
  }

  .question {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;

    &-list {
      padding: 15px 0;
    }
  }

  &__alert {
    margin-bottom: 20px;

    .ant-alert-description {
      color: #fff;
    }
  }
}

.pin_code__position {
  position: absolute;
  right: 0;
  top: -10px;
}
