.logs {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .warn {
    background-color: #fff7e6;
  }
  .err {
    background-color: #fff1f0;
  }

  .logs-client {
    max-width: 400px;
    white-space: nowrap;
  }

  .created-at-cell {
    white-space: nowrap;
  }

  .message-key {
    font-weight: bold;
    margin-inline-end: 5px;
    &:after {
      content: ':';
    }
  }

  .display-field {
    margin-block-end: 5px;

    .left-section {
      min-width: 120px;
      height: 100%;
    }
  }

  table > tbody > tr > td {
    max-width: 250px;
  }
}

.filter-form {
  .ant-form-item-label {
    padding: 0;
  }
}
