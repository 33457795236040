.file-list {
  &__file-element {
    position: relative;
    padding: 5px;
    button {
      position: absolute;
      display: none;
      inset-block-start: 0;
      inset-inline-end: 0;
    }
    &:hover {
      button {
        display: inline-block;
      }
    }
  }
}
.calc-formula {
  width: calc(100% - 62px);
  margin: 0;
}

.rof-date-input-wrapper {
  display: flex;
  height: 32px;
  .rof-date-input-mode {
    flex: 150px 0 0;
  }
  .rof-date-input-value {
    flex: auto 1 1;
    padding-inline: 8px;
    .rof-date-input-value-wrapper {
      > :not(:last-child) {
        margin-inline-end: 4px;
      }
    }
  }
}

.calculated-field-saving {
  color: var(--roche-gray-text);
  font-size: 0.9em;
}
