.title-actions {
  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    flex-wrap: wrap;
    > a,
    > span {
      display: flex !important;
    }
    .ant-btn-button,
    .ant-btn-default,
    .ant-btn-primary,
    .rc-upload,
    a {
      svg {
        margin-inline-end: 8px;
      }
    }
  }
}
