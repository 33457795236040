.expire-modal {
  max-width: 450px;
  margin: 0;
  right: 10px;
  top: 10px;
  position: absolute;

  &__wrapper {
    pointer-events: none;
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 16px;
  }
  &__desc {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
  button {
    font-size: 15px;
    font-weight: bold;
  }
  .ant-modal-footer {
    border: none;
  }
}
