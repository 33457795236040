.disclaimer-message {
  display: flex;
  gap: 0.25em;
  align-items: flex-start;
  line-height: 1;
  margin-block-end: 8px;
  article {
    margin-block-end: 8px;
  }
}
