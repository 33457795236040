.privileges {
  &__main-buttons {
    display: flex;
    gap: 10px;
  }
  .collapse-header {
    align-items: center;
    display: flex;
  }
  .main-privileges{
    h4 {
      font-weight: bold;
    }
    margin: 15px 0;
  }
}
  

.new-role-input {
  margin-bottom: 10px;
}

.roles-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  .roles-list-element {
    padding-block-start: 10px;

    table {
      th {
        &.role-column-privileges {
          width: 120px;
          .ant-checkbox-wrapper {
            color: #b5b5c3;
          }
        }
      }
      tr:last-child td {
        border-bottom: none;
      }
    }
  }

  &-sub {
    padding-inline-start: 16px;
    .roles-list-element {
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        border-inline-start: 1px solid #d9d9d9;
        border-block-end: 1px solid #d9d9d9;
        inset: 0 0 0 -10px;
        width: 10px;
        height: 41px;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        border-inline-start: 1px solid #d9d9d9;
        border-block-start: 1px solid #d9d9d9;
        width: 10px;
        inset: 40px 0 0 -10px;
      }
      &:last-child::after {
        display: none;
      }
    }
  }
}

.roles-panel {
  .ant-collapse-header {
    font-size: 22px;
    font-weight: bold;
    svg {
      font-size: 15px;
    }
  }
  .view-icon {
    font-size: 18px;
    color: #0066cc;
  }
  .view-icon {
    &__info {
      color: rgb(234, 138, 34);
      font-size: 14px !important;
      position: absolute !important;
      top: -10px !important;
      left: -20px !important;
    }
    &__plus {
      color: #0066cc;
      font-size: 14px !important;
      position: absolute !important;
      top: -10px !important;
      left: -20px !important;
    }
  }
  .permission-text {
    font-size: 13px;
    color: #333333;
    margin-inline-start: 5px;
    font-weight: normal;
  }
  .permission-icon {
    font-size: 13px;
    margin-inline-start: 15px;
    &-on {
      color: #0066cc;
    }
    &-off {
      color: #a7a8ab;
    }
  }
}

.actions-col {
  width: 145px;
  .list-action {
    display: inline-block;
    width: 77px;
    text-align: center;

    a:first-child .ant-btn {
      padding-inline-start: 0;
    }
  }
}
