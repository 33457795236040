.change-password {
  .gen-pswd {
    position: absolute;
    inset-inline-end: 25px;
    cursor: pointer;
    z-index: 2;
    padding: 0 0 8px 0;
  }

  .logout-warning {
    margin-bottom: 20px;
    height: 56px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
