.dragging {
  display: table;
  background: #fafafa;
  cursor: grabbing;
}
.action-drag {
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  display: inline-block;
  color: #0066cc;
  &:hover {
    color: #2384d9;
  }
}
.schema-panel {
  .schema-panel__list {
    .schema-panel__list__item {
      background-color: white;
      border-bottom: 1px solid #f0f0f0;
    }
  }
  &__label {
    color: #7d7f84;
  }
  .rosis-column-actions {
    width: 170px;
  }
  td.rosis-column-actions {
    padding-inline: 1px;
  }
  .conditions {
    margin: 15px 0;
  }
}

.require-star {
  &:before {
    display: inline-block;
    margin-right: 4px;
    color: #f0265c;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.fields-row {
  padding: 7px 14px;
  border-bottom: 1px solid #ebedf3;
  display: grid;
  grid-template-columns: 1fr 1fr 150px;
  align-items: center;
  &.fields-header {
    color: #00000040;
  }
  &.no-fields {
    grid-template-columns: 1fr;
    text-align: center;
    color: #00000040;
  }
  &:last-child {
    border: none;
  }
}

.hide-field {
  opacity: 0.35;
}

.conditions-icon {
  white-space: nowrap;
  font-weight: bold;
  font-size: 10px;
}

.field-display-icon {
  display: flex;
  gap: 5px;
}

.hint-section-input {
  display: flex;
  margin-top: 5px;
  margin-bottom: 15px;
  color: var(--roche-yellow);

  svg {
    width: 15px;
    height: 15px;
    margin-inline-end: 5px;
  }
}
