.lang-add {
  margin-bottom: 10px;
}
.lang {
  tr td:first-child,
  td:nth-child(2) {
    width: 30%;
  }
  .lang tr td:nth-child(3) {
    width: 20%;
  }
}
.lang-btn {
  font-weight: bold;
}
.add-new-language-modal {
  .translated-names-panel {
    max-height: 200px;
    overflow-y: auto;
    padding-inline-end: 5px;
  }
}
