.lang {
  .only-missing {
    margin-bottom: 10px;
  }

  .missing {
    color: var(--roche-red);
  }

  .ant-checkbox-disabled + span {
    color: #000;
  }
}

.ant-popover {
  z-index: 9999999;
}

.edit-translation__btn {
  border: 1px solid #f5f5f5;
  background-color: #fff;
  padding: 4px 15px;
  width: 100%;
}
