.forgot-password {
  padding-top: 40px;

  .forgot-password__title {
    text-align: center;
  }
  .forgot-password__card {
    max-width: 400px;
    margin-inline: auto;
    box-shadow: 0 2px 6px rgba(45, 59, 72, 0.15);
    background-color: rgba(256, 256, 256, 0.95);
  }

  .forgot-password__form {
    .ant-form-item {
      margin-bottom: 16px;
    }
    label {
      padding: 6px 0;
      display: block;
    }
    .ant-checkbox-wrapper {
      display: inline-flex;
    }
    button.ant-btn-primary {
      padding: 10px 35px;
      height: auto;
      font-weight: bold;
    }
    button.ant-btn-link {
      padding: 3px;
    }
  }

  .buttons .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.pin-code__info {
  margin-inline-start: 5px;
  color: var(--roche-gray-text);
}
