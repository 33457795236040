.server-error {
  max-width: 680px;
  margin: 90px auto 0;
  .ant-result-title {
    font-weight: bold;
  }
  .ant-result-subtitle {
    margin-top: 20px;
    font-size: 14px;
    color: #333333;
  }
  .ant-btn {
    padding-inline: 30px;
  }
}
