@main-roche-color: #0066cc;
@text-color-inverse: #fff;
@red-base: #e40046;
@red-message: #e21b48;
@purple-base: #a05eb5;
@green-base: #00965e;
@green-message: #009460;
@alert-success-bg-color: #e0efea;
@alert-warning-bg-color: #fffbe6;
@yellow-base: #ffc72c;
@orange-base: #ed8b00;
@orange-message: #ea8a22;
@blue-base: @main-roche-color;
@blue-message: @blue-base;
@gray-base: #f0f2f5;
@gray-text: #b5b5c3;
@text-selection-bg: @main-roche-color;
@primary-color: @main-roche-color;
@btn-primary-bg: @main-roche-color;
@menu-highlight-color: @main-roche-color;
@link-color: @main-roche-color;
@link-hover-color: lighten(@main-roche-color, 30%);
@layout-sider-background: @component-background;
@layout-header-background: @main-roche-color;
@layout-trigger-background: @main-roche-color;
@layout-header-color: #fff;
@global-drag-line: @main-roche-color;
@input-hover-border-color: @main-roche-color;
@outline-color: @main-roche-color;
@select-item-selected-color: @main-roche-color;
@select-item-selected-bg: lighten(@main-roche-color, 50%);
@disabled-item-color: rgba(0, 0, 0, 0.25);

:root {
  --roche-main: @main-roche-color;
  --roche-red: @red-base;
  --roche-red-message: @red-message;
  --roche-violet: @purple-base;
  --roche-green: @green-base;
  --roche-green-message: @green-message;
  --roche-gray: @gray-base;
  --roche-gray-text: @gray-text;
  --roche-yellow: @yellow-base;
  --roche-orange: @red-base;
  --roche-orange-message: @orange-message;
  --roche-blue: @blue-base;
  --roche-blue-message: @blue-message;
  --roche-disabled-gray: @disabled-item-color;
}
