.dashboard {
  margin: 10px;

  &-title__section {
    font-weight: bold;
    margin-inline-start: 8px;
  }

  &-version__name {
    color: #7d7f84;
  }

  &-stats__value {
    color: #0066cc;
    font-size: 17px;
    font-weight: bold;
  }

  .log-section {
    margin-bottom: 25px;
    min-height: 300px;

    &_timeline {
      height: 300px;
      overflow-y: auto;
      padding: 5px;
    }
  }
  .users-section {
    margin-bottom: 25px;
    min-height: 300px;

    &_list {
      height: 300px;
      overflow-y: auto;
      padding: 5px;
    }
  }

  .ant-list-item-action {
    margin-top: 15px;
  }

  .reject-user_button {
    padding: 0;
  }
  .accept-user_button {
    padding: 0;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .ant-list-item-meta-title {
    line-break: anywhere;
  }

  .ant-card-extra {
    padding-inline-start: 10px;
  }
}
