.conditions {
  margin-bottom: 30px;
  &__elements {
    display: grid;
    grid-template-columns: 70px 200px 32px 200px 20px;
    grid-gap: 10px 5px;
  } 
  &__element {
    display: flex;
  }
  > button {
    margin-top: 10px;
  }
}
