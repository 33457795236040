.pin-code {
    &__time-expired {
        font-weight: bold;
        color: var(--roche-main);
        font-size: 1.1em;
    }
    &__input-code-element {
        font-size: 24px;
        font-weight: bold;
        display: flex;
        align-items: center;
        width: 42px;
        height: 56px;
        & .ant-input-number-handler-wrap {
            display: none;
        }
        & .ant-input-number-input {
            width: 42px;
            height: 56px;
            text-align: center;
        }
    } 
    &__button {
        padding: 8px 20px;
        height: 40px;
    }
}