@action-col-width: 70px;
@table-padding: 10px 10px 10px 40px;
@table-padding-table: 10px 40px;

.sections {
  .ant-table-thead {
    .section-col {
      &::before {
        display: none;
      }
    }
  }
  td.actions-col {
    padding-inline: 1px;
  }
  .actions-col {
    width: 200px;
    .list-action {
      display: inline-block;
      width: 30px;
      text-align: center;
    }
  }
  .ant-table-tbody > tr > td {
    overflow-wrap: normal;
  }
}

.table-dnd {
  &_sub-elements {
    margin-inline-start: 43px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: white;
      height: 7px;
      width: 3px;
      inset: 1px 0 0 -23px;
      z-index: 1;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      border-block-start: 1px solid #ebedf3;
      width: 43px;
      inset: 0 0 0 -43px;
    }

    div.table-dnd_row {
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        border-inline-start: 1px solid #d9d9d9;
        border-block-end: 1px solid #d9d9d9;
        inset: 0 0 0 -22px;
        width: 22px;
        height: 30px;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        border-inline-start: 1px solid #d9d9d9;
        width: 22px;
        inset: 30px 0 0 -22px;
      }
      &.last-row {
        &::after {
          content: none;
        }
      }
    }

    .dragging.table-dnd_row {
      &::after {
        content: none;
      }
      &::before {
        content: none;
      }
    }
  }

  &_row {
    &_main {
      border-top: 1px solid #ebedf3;
      padding: @table-padding;
      display: flex;
      justify-content: space-between;
      @media (min-width: 720px) {
        padding: @table-padding-table;
      }
      &:hover {
        background-color: #fafafa;
      }
      &_title {
        display: flex;
        align-items: center;
        .title {
          position: relative;
          .expend-btn {
            inset: calc(50% - 8px) 0 0 -30px;
            position: absolute;
            width: 17px;
            height: 17px;
            background: #fff;
            border: 1px solid #f0f0f0;
            border-radius: 2px;
            cursor: pointer;
            transform: scale(0.94117647);
            transition: all 0.3s;

            &:before,
            &:after {
              position: absolute;
              background: currentcolor;
              transition: transform 0.3s ease-out;
              content: '';
            }

            &:before {
              top: 7px;
              right: 3px;
              left: 3px;
              height: 1px;
            }
            &:after {
              top: 3px;
              bottom: 3px;
              left: 7px;
              width: 1px;
              transform: rotate(0);
            }
            &.expended:after {
              transform: rotate(90deg);
            }
            &:hover {
              color: var(--roche-main);
              border-color: currentcolor;
            }
          }
        }
      }
      &_actions {
        min-width: @action-col-width;
        button,
        .action-drag {
          padding: 4px 10px;
        }
      }
    }
  }
  &_header {
    color: #b5b5c3;
    display: flex;
    padding: @table-padding;
    display: flex;
    justify-content: space-between;
    @media (min-width: 720px) {
      padding: @table-padding-table;
    }
    &_actions {
      min-width: @action-col-width;
    }
  }
}
