.admin-content {
  &.app-content {
    min-height: calc(100vh - 64px);
    & .page-toolbar {
      position: sticky;
      top: 0;
      z-index: 10;
      min-height: 25px;
      padding: 0 10px;
      margin-top: 5px;
      background: var(--roche-gray);
      & > .ant-card {
        min-height: 64px;
        & > .ant-card-body {
          padding: 24px 24px 12px 24px;
        }
      }
    }
    & > .ant-card {
      margin: 10px;
      box-shadow: 0 2px 6px 0 rgba(45, 59, 72, 0.15);
      & .ant-card-body {
        padding: 15px;
        @media (min-width: 769px) {
          padding: 20px;
        }
      }
    }
    .site-layout-top-bar {
      display: flex;
      align-items: center;
      gap: 15px;
      min-height: 40px;
      margin-bottom: 10px;
      .info-bar-alert {
        flex: auto 1 1;
      }
    }
  }
  .site-layout-top-bar {
    display: flex;
    align-items: center;
    gap: 15px;
    min-height: 40px;
    margin-bottom: 10px;
    .info-bar-alert {
      flex: auto 1 1;
    }
  }
}
.info-bar-alert {
  display: flex;
  justify-content: flex-end;
  &.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
    animation-name: antMoveRightOut;
  }
  .ant-message-notice-content {
    padding: 0;
    margin-right: 10px;
    min-width: 75vw;
    margin-bottom: 3px;
    cursor: pointer;
    @media (min-width: 769px) {
      min-width: 50vw;
    }
    @media (min-width: 992px) {
      min-width: 33vw;
    }
    .ant-alert-message {
      text-align: left;
      div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  &:last-of-type {
    .ant-alert {
      padding: 18px 15px;
      font-weight: bold;
    }
    .anticon {
      font-size: 18px;
    }
  }
}
body {
  .ant-message {
    top: auto;
    bottom: 8px;
  }
}

.bar-bottom-shadow {
  box-shadow: 0 2px 4px #dddfe1;
}
.ant-alert {
  .ant-alert-icon,
  .ant-alert-message,
  .anticon-close {
    color: white;
  }
}
.ant-alert-success {
  background-color: var(--roche-green-message);
  border-color: var(--roche-green-message);
}
.ant-alert-info {
  background-color: var(--roche-blue-message);
  border-color: var(--roche-blue-message);
}
.ant-alert-error {
  background-color: var(--roche-red-message);
  border-color: var(--roche-red-message);
}
.ant-alert-warning {
  background-color: var(--roche-orange-message);
  border-color: var(--roche-orange-message);
}
