.pin-code {
  padding-top: 40px;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;

  &__title {
    text-align: left;
    font-size: 26px;
  }
  &__card {
    max-width: 400px;
    margin-inline: auto;
    box-shadow: 0 2px 6px rgba(45, 59, 72, 0.15);
    background-color: rgba(256, 256, 256, 0.95);
  }

  .btn-procced {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  &__link {
    padding: 0;
  }
}
